import React from "react";

const LeagueTwo = () => {
  return (
    <div className="football_flex_item">
      <table>
        {/* <table className="l2_formatting"> */}
        <caption>League Two</caption>

        <colgroup>
          <col span="2" className="team_position"></col>
          <col span="3" className="points"></col>
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Team</th>
            <th>P</th>
            <th>GD</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Forest Green</td>
            <td>15</td>
            <td>14</td>
            <td>31</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Port Vale</td>
            <td>15</td>
            <td>12</td>
            <td>28</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Northampton</td>
            <td>15</td>
            <td>9</td>
            <td>27</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Exeter</td>
            <td>15</td>
            <td>10</td>
            <td>26</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Swindon</td>
            <td>15</td>
            <td>7</td>
            <td>26</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Leyton Orient</td>
            <td>15</td>
            <td>13</td>
            <td>23</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Harrogate</td>
            <td>15</td>
            <td>7</td>
            <td>23</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Newport</td>
            <td>15</td>
            <td>7</td>
            <td>23</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Sutton United</td>
            <td>15</td>
            <td>5</td>
            <td>23</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Hartlepool</td>
            <td>15</td>
            <td>-3</td>
            <td>23</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Tranmere</td>
            <td>15</td>
            <td>1</td>
            <td>22</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Bradford</td>
            <td>15</td>
            <td>3</td>
            <td>21</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Rochdale</td>
            <td>15</td>
            <td>0</td>
            <td>20</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Walsall</td>
            <td>15</td>
            <td>0</td>
            <td>20</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Barrow</td>
            <td>15</td>
            <td>0</td>
            <td>20</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Bristol Rovers</td>
            <td>15</td>
            <td>-6</td>
            <td>18</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Colchester</td>
            <td>15</td>
            <td>-7</td>
            <td>17</td>
          </tr>
          <tr>
            <td>18</td>
            <td>Crawley</td>
            <td>15</td>
            <td>-8</td>
            <td>17</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Salford</td>
            <td>15</td>
            <td>-1</td>
            <td>16</td>
          </tr>
          <tr>
            <td>20</td>

            <td>Mansfield</td>
            <td>15</td>
            <td>-6</td>
            <td>14</td>
          </tr>
          <tr>
            <td>21</td>

            <td>Stevenage</td>
            <td>15</td>
            <td>-15</td>
            <td>14</td>
          </tr>
          <tr>
            <td>22</td>

            <td>Oldham</td>
            <td>15</td>
            <td>-11</td>
            <td>12</td>
          </tr>
          <tr>
            <td>23</td>
            <td>Carlisle</td>
            <td>15</td>
            <td>-6</td>
            <td>14</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Scunthorpe</td>
            <td>15</td>
            <td>-18</td>
            <td>11</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default LeagueTwo;
