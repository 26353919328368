import React from "react";

const LeagueOne = () => {
  return (
    <div className="football_flex_item">
      <table className="l1_formatting">
        <caption>League One</caption>

        <thead>
          <tr>
            <th></th>
            <th>Team</th>
            <th>P</th>
            <th>GD</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Wigan</td>
            <td>16</td>
            <td>17</td>
            <td>34</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Plymouth</td>
            <td>16</td>
            <td>17</td>
            <td>31</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Rotherham</td>
            <td>16</td>
            <td>17</td>
            <td>31</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Wycombe</td>
            <td>16</td>
            <td>6</td>
            <td>31</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Sunderland</td>
            <td>15</td>
            <td>4</td>
            <td>28</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Oxford United</td>
            <td>15</td>
            <td>9</td>
            <td>27</td>
          </tr>
          <tr>
            <td>7</td>
            <td>MK Dons</td>
            <td>15</td>
            <td>6</td>
            <td>25</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Sheffield Wednesday</td>
            <td>16</td>
            <td>5</td>
            <td>25</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Ipswich</td>
            <td>16</td>
            <td>9</td>
            <td>23</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Cambridge</td>
            <td>16</td>
            <td>-2</td>
            <td>22</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Burton</td>
            <td>16</td>
            <td>-5</td>
            <td>22</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Accrington</td>
            <td>15</td>
            <td>-6</td>
            <td>21</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Lincoln City</td>
            <td>16</td>
            <td>0</td>
            <td>20</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Portsmouth</td>
            <td>16</td>
            <td>-2</td>
            <td>20</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Cheltenham</td>
            <td>16</td>
            <td>-7</td>
            <td>20</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Bolton</td>
            <td>16</td>
            <td>-4</td>
            <td>19</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Wimbledon</td>
            <td>15</td>
            <td>-4</td>
            <td>17</td>
          </tr>
          <tr>
            <td>18</td>
            <td>Charlton</td>
            <td>16</td>
            <td>-3</td>
            <td>16</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Gillingham</td>
            <td>16</td>
            <td>-6</td>
            <td>16</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Morecambe</td>
            <td>16</td>
            <td>-5</td>
            <td>15</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Shrewsbury</td>
            <td>16</td>
            <td>-7</td>
            <td>15</td>
          </tr>
          <tr className="my_teams">
            <td>22</td>
            <td>Fleetwood Town</td>
            <td>15</td>
            <td>-1</td>
            <td>14</td>
          </tr>
          <tr>
            <td>23</td>
            <td>Doncaster</td>
            <td>16</td>
            <td>18</td>
            <td>12</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Crewe</td>
            <td>16</td>
            <td>-17</td>
            <td>9</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default LeagueOne;
