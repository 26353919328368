import React from "react";

const Championship = () => {
  return (
    <div className="football_flex_item">
      <table className="ch_formatting">
        <caption>Championship</caption>
        <thead>
          <tr>
            <th></th>
            <th>Team</th>
            <th>P</th>
            <th>GD</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Bournemouth</td>
            <td>17</td>
            <td>21</td>
            <td>41</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Fulham</td>
            <td>17</td>
            <td>30</td>
            <td>38</td>
          </tr>
          <tr>
            <td>3</td>
            <td>West Brom</td>
            <td>17</td>
            <td>12</td>
            <td>32</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Coventry</td>
            <td>17</td>
            <td>4</td>
            <td>30</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Stoke</td>
            <td>17</td>
            <td>3</td>
            <td>28</td>
          </tr>
          <tr>
            <td>6</td>
            <td>QPR</td>
            <td>17</td>
            <td>4</td>
            <td>26</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Blackburn</td>
            <td>17</td>
            <td>2</td>
            <td>26</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Huddersfield</td>
            <td>17</td>
            <td>1</td>
            <td>25</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Millwall</td>
            <td>17</td>
            <td>0</td>
            <td>25</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Blackpool</td>
            <td>17</td>
            <td>-1</td>
            <td>25</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Luton</td>
            <td>17</td>
            <td>4</td>
            <td>24</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Swansea</td>
            <td>17</td>
            <td>-2</td>
            <td>23</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Nottingham Forest</td>
            <td>17</td>
            <td>1:</td>
            <td>22</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Middlesbrough</td>
            <td>17</td>
            <td>0</td>
            <td>22</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Birmingham</td>
            <td>17</td>
            <td>-1</td>
            <td>22</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Reading</td>
            <td>17</td>
            <td>-6</td>
            <td>22</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Preston</td>
            <td>17</td>
            <td>-4</td>
            <td>21</td>
          </tr>
          <tr>
            <td>18</td>

            <td>Sheffield United</td>
            <td>17</td>
            <td>-4</td>
            <td>19</td>
          </tr>
          <tr>
            <td>19</td>

            <td>Bristol City</td>
            <td>17</td>
            <td>-8</td>
            <td>19</td>
          </tr>
          <tr>
            <td>20</td>

            <td>Cardiff</td>
            <td>17</td>
            <td>-14</td>
            <td>15</td>
          </tr>
          <tr>
            <td>21</td>

            <td>Peterborough</td>
            <td>17</td>
            <td>-15</td>
            <td>15</td>
          </tr>
          <tr>
            <td>22</td>

            <td>Hull</td>
            <td>17</td>
            <td>-12</td>
            <td>12</td>
          </tr>
          <tr>
            <td>23</td>

            <td>Barnsley</td>
            <td>17</td>
            <td>13</td>
            <td>11</td>
          </tr>
          <tr>
            <td>24</td>

            <td>Derby</td>
            <td>17</td>
            <td>-3</td>
            <td>6</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default Championship;
